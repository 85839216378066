import { render, staticRenderFns } from "./DataSearch.vue?vue&type=template&id=d0072fca&scoped=true"
import script from "./DataSearch.vue?vue&type=script&lang=js"
export * from "./DataSearch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0072fca",
  null
  
)

export default component.exports