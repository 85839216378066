var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"gridView"}},[_c('PageHeader',[_c('div',{attrs:{"slot":"icon"},slot:"icon"},[_c('span',{staticClass:"slds-icon_container slds-icon-standard-account",attrs:{"title":"scan"}},[_c('svg',{staticClass:"slds-icon slds-icon_small",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg") + "#custom62"}})]),_c('span',{staticClass:"slds-assistive-text"},[_vm._v("scan")])])]),_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Scans")]),_c('div',{attrs:{"slot":"subtitle"},slot:"subtitle"},[_vm._v(_vm._s(_vm.scans.length)+" records")]),_c('div',{attrs:{"slot":"actions"},slot:"actions"},[_c('div',{staticClass:"slds-page-header__controls"},[_c('div',{staticClass:"slds-page-header__control"},[_c('button',{staticClass:"slds-button slds-button_icon slds-button_icon-border-filled",attrs:{"title":"Refresh List","disabled":_vm.actionInProgress},on:{"click":_vm.refreshScans}},[_c('svg',{staticClass:"slds-button__icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg") + "#refresh"}})]),_c('span',{staticClass:"slds-assistive-text"},[_vm._v("Refresh List")])])]),_c('div',{staticClass:"slds-page-header__control"},[(_vm.id)?_c('JsonCSV',{staticClass:"slds-button slds-button_icon slds-button_icon-border-filled",attrs:{"title":"Download CSV","data":_vm.scans,"name":'snapshot-' + _vm.id + '-scans.csv'}},[_c('svg',{staticClass:"slds-button__icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg") + "#download"}})]),_c('span',{staticClass:"slds-assistive-text"},[_vm._v("Download CSV")])]):_vm._e()],1)])])]),_c('DataTable',{attrs:{"type":"scan","displayRows":_vm.scans,"columns":[
      {
        title: 'Id',
        field: 'id',
      },
      {
        title: 'Metadata',
        params: {
          id: 'id',
        },
        parentField: 'metadata',
        type: 'snapshot',
        childField: 'name',
      },
      {
        title: 'Status',
        field: 'status',
      },

      {
        title: 'Created By',
        field: 'createdBy',
      },
      {
        title: 'Start Date',
        field: 'startDate',
      },
      {
        title: 'End Date',
        field: 'endDate',
      },
    ],"actions":[
      { label: 'Export to CSV', name: 'download' },
      { label: 'Delete', name: 'delete' },
    ]},on:{"download":_vm.handleDownload,"delete":_vm.handleDelete}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }