var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageHeader',{staticClass:"slds-m-bottom_small"},[_c('div',{attrs:{"slot":"icon"},slot:"icon"},[_c('span',{staticClass:"slds-icon_container slds-icon-standard-opportunity"},[_c('svg',{staticClass:"slds-icon slds-page-header__icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg") + "#custom38"}})])])]),(_vm.snapshot)?_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.snapshot.name))]):_vm._e(),_c('div',{attrs:{"slot":"subtitle"},slot:"subtitle"},[_vm._v("Id: "+_vm._s(_vm.id))]),(_vm.isSnapshotAvailable)?_c('div',{attrs:{"slot":"actions"},slot:"actions"},[_c('div',{staticClass:"slds-button-group",attrs:{"role":"group"}},[_c('button',{staticClass:"slds-button slds-button_neutral",on:{"click":_vm.showSnapshotEditor}},[_vm._v("Rename")]),_c('button',{staticClass:"slds-button slds-button_destructive",attrs:{"disabled":_vm.actionInProgress},on:{"click":_vm.handleDeleteSnapshot}},[_vm._v(" Delete ")])])]):_vm._e(),(_vm.snapshot)?_c('div',{attrs:{"slot":"details"},slot:"details"},[_c('ul',{staticClass:"slds-page-header__detail-row"},[_c('li',{staticClass:"slds-page-header__detail-block"},[_c('div',{staticClass:"slds-text-title slds-truncate"},[_vm._v("Status")]),_c('div',{staticClass:"slds-truncate",attrs:{"title":_vm.snapshot.status}},[_vm._v(" "+_vm._s(_vm.snapshot.status)+" ")])]),(_vm.snapshot.connection)?_c('li',{staticClass:"slds-page-header__detail-block"},[_c('div',{staticClass:"slds-text-title slds-truncate"},[_vm._v("Connection Org")]),_c('div',{staticClass:"slds-truncate",attrs:{"title":_vm.snapshot.connection.name}},[_c('router-link',{attrs:{"to":{
                name: 'connectionSnapshots',
                params: { id: _vm.snapshot.connection.id },
              }}},[_vm._v(_vm._s(_vm.snapshot.connection.name))])],1)]):_vm._e(),_c('li',{staticClass:"slds-page-header__detail-block"},[_c('div',{staticClass:"slds-text-title slds-truncate"},[_vm._v("Created By")]),_c('div',{staticClass:"slds-truncate",attrs:{"title":_vm.snapshot.createdBy.name}},[_vm._v(" "+_vm._s(_vm.snapshot.createdBy.name)+" ")])]),_c('li',{staticClass:"slds-page-header__detail-block"},[_c('div',{staticClass:"slds-text-title slds-truncate"},[_vm._v("Created Date")]),_c('div',{staticClass:"slds-truncate",attrs:{"title":_vm._f("readableDate")(_vm.snapshot.createdAt)}},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.snapshot.createdAt,'from'))+" ")])])])]):_vm._e()]),(_vm.snapshot && _vm.snapshot.errorLogs)?_vm._l((_vm.snapshot.errorLogs),function(error){return _c('StaticInformationBox',{key:error.id,attrs:{"type":"error"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Error - "+_vm._s(error.message))]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('p',[_vm._v("StackTrace: "+_vm._s(error.stackTrace))])])])}):_vm._e(),_c('DetailCard',[_c('div',{attrs:{"slot":"icon"},slot:"icon"},[_c('span',{staticClass:"slds-icon_container slds-icon-standard-account",attrs:{"title":"scan"}},[_c('svg',{staticClass:"slds-icon slds-icon_small",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg") + "#custom62"}})]),_c('span',{staticClass:"slds-assistive-text"},[_vm._v("scan")])])]),_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('router-link',{staticClass:"slds-card__header-link slds-truncate",attrs:{"to":{
          name: 'scans',
          params: { snapshotId: _vm.id },
        },"title":"Scans"}},[_c('span',[_vm._v("Scans")])])],1),_c('div',{attrs:{"slot":"actions"},slot:"actions"},[_c('div',{staticClass:"slds-no-flex"},[_c('div',{staticClass:"slds-no-flex"},[_c('button',{staticClass:"slds-button slds-button_icon slds-button_icon-border-filled",attrs:{"title":"Refresh List","disabled":_vm.actionInProgress},on:{"click":_vm.handleRefreshScans}},[_c('svg',{staticClass:"slds-button__icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg") + "#refresh"}})]),_c('span',{staticClass:"slds-assistive-text"},[_vm._v("Refresh List")])]),(_vm.snapshot && !_vm.snapshot.error)?_c('JsonCSV',{staticClass:"slds-button slds-button_icon slds-button_icon-border-filled",attrs:{"title":"Download CSV","data":_vm.scans,"name":'snapshot-' + _vm.id + '-scans.csv'}},[_c('svg',{staticClass:"slds-button__icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":require("@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg") + "#download"}})]),_c('span',{staticClass:"slds-assistive-text"},[_vm._v("Download CSV")])]):_vm._e()],1)])]),_c('div',{attrs:{"slot":"body"},slot:"body"},[(_vm.snapshot && _vm.snapshot.error)?_c('div',{staticStyle:{"margin":"10px"}},[_c('StaticInformationBox',{attrs:{"type":"error"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Snapshot Error")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('pre',{staticClass:"slds-text-font_monospace slds-text-body_small"},[_vm._v(_vm._s(_vm.snapshot.error))])])])],1):_vm._e(),_c('DataTable',{attrs:{"type":"scan","display-rows":_vm.scans,"columns":[
          {
            title: 'Id',
            field: 'id',
          },
          {
            title: 'Status',
            field: 'status',
          },
          {
            title: 'Created By',
            field: 'createdBy',
          },
          {
            title: 'Start Date',
            field: 'startDate',
          },
          {
            title: 'End Date',
            field: 'endDate',
          },
        ],"actions":[{ label: 'Delete', name: 'delete' }]},on:{"delete":_vm.handleDeleteScan}})],1)]),_c('SnapshotLogs',{attrs:{"snapshot":_vm.snapshot}}),(_vm.snapshotEditorVisible)?_c('SnapshotEditor',{attrs:{"show":_vm.snapshotEditorVisible,"snapshot":_vm.snapshot},on:{"close":_vm.handleSnapshotEditorClose}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }